.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
}

.image {
  width: 100%;
  border-radius: 10px;
}
.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 414px) {
	.left-arrow{
		left:5px;
	}
	.right-arrow{
		right:5px;
	}
}