.typing{
	width:22ch;
	animation: typing 2s steps(22), blink .5s step-end infinite alternate;
	white-space: nowrap;
	overflow: hidden;
	border-right: 3px solid;
	
}
.typing2{
	width:44ch;
	animation: typing 2s steps(22), blink .5s step-end infinite alternate;
	white-space: nowrap;
	overflow: hidden;
	border-right: 3px solid;	
}
.floating{
	animation:floating 2s 2s forwards;
	opacity:0;
	margin-top:30px;
}
.floating2{
	animation:floating 2s 2s forwards;
	opacity:0;
	margin-top:30px;
}
@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}
@keyframes floating{
	to{
		opacity:1;
		margin-top:0;
	}
}
@media(max-width:959px){
	.floating2{
		display:none;
	}
}