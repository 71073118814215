.category{
	font-family:"Noto Sans";
	display:flex;
	align-items:center;
	justify-content:center;
	height:60px;
	font-size:20px;
	padding-right:5px;
	padding-left:5px;
}
.formtag{
	text-align:center;
}
@media(min-width:768px){
	.category{
		height:100px;
		font-size:25px;
		padding-right:15px;
		padding-left:15px;
	}
}
@media(min-width:820px){
	.category{
		padding-left:25px;
		padding-right:25px;
	}
}
@media(min-width:1000px){
	.hamber{
		display:none;
	}
}
@media(max-width:1012px){
	.navbar{
		width:100%;
	}
}
.category:hover{
	border-bottom:3px solid #6667ab;
}